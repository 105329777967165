import React from "react"

import { css, Global, keyframes } from "@emotion/react"
import styled, { CSSObject } from "@emotion/styled"
import { GatsbyLinkProps } from "gatsby"
import { Link } from "gatsby-plugin-intl"
import {
  between,
  border,
  darken,
  lighten,
  padding,
  readableColor,
  transparentize,
} from "polished"
import { ThreeDots } from "react-loader-spinner"
import createShevy from "shevyjs"

import CustomerChat from "./CustomerChat"
import { MontserratFontImport } from "./fonts"

export const shevy = createShevy({
  baseFontSize: "1em",
  fontScale: [3.6, 3, 2, 1.5],
})
export const shevySm = createShevy({
  baseFontSize: "1em",
})

// Base fontsize
export const fs = 20
// Convert pixels (from design) to ems based on base fontsize
export const toEm = (px: number, fontSize: number = fs): string =>
  px / fontSize + "em"

export const theme = {
  colors: {
    red: "#ED5351",
    black: "#3D3D3D",
    darkGrey: "#7C7C7D",
    grey: "#F6F6F6",
    green: "#88AF46",
    // green: "#7a9d3f", // <- better contrast
    orange: "#FFBB34",
    white: "#ffffff",
    blue: "#33B5E5",
  },
}

export const bpSm = "@media(max-width: 800px)"
export const overBpSm = "@media(min-width: 800px)"

export const P = styled.p({
  ...shevySm.content,
  [overBpSm]: { ...shevy.content },
})
export const H1 = styled.h1({
  ...shevySm.h1,
  [overBpSm]: { ...shevy.h1 },
})
export const H2 = styled.h2({
  ...shevySm.h2,
  [overBpSm]: { ...shevy.h2 },
})
export const H3 = styled.h3({
  ...shevySm.h3,
  [overBpSm]: { ...shevy.h3 },
})
export const H4 = styled.h4({
  ...shevySm.h4,
  [overBpSm]: { ...shevy.h4 },
})

export const bp = "@media(min-width: 800px)"
export const bpLg = "@media(min-width: 1024px)"

export const Wrapper: React.FC = ({ children }) => {
  return (
    <>
      <MontserratFontImport />
      <Global
        styles={{
          "*": { boxSizing: "border-box" },
          html: {
            fontSize: 16,
            [bp]: { fontSize: 20 },
          },
          "body, html": {
            overflowX: "hidden",
            width: "100vw",
          },
          body: {
            margin: 0,
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 500,
          },
          "input, textarea": {
            fontFamily: "'Montserrat', sans-serif",
          },
          "h1, h2, h3, p, figure": {
            margin: 0,
          },
        }}
      />
      {children}
      <CustomerChat />
    </>
  )
}

// calc(1em + ((100vw - 1440px) / (2880 - 1440)))
export const Layout: React.FC = ({ children, ...props }) => {
  return (
    <div
      css={{
        color: theme.colors.black,
        fontSize: `clamp(16px, ${between(
          `${fs}px`,
          `${fs * 2}px`,
          "1440px",
          1440 * 2 + "px"
        )}, 30px)`,
        // fontSize: `calc(1em + 0.06vw)`,
        // fontSize: `calc(1em + ((100vw - 1440px) / (2880 - 1440)))`,
        // fontSize: between(`${1}rem`, `${2}rem`, "1440px", 1400 * 2 + "px"),
        // fontSize: between2(1, 4, 1440, 1440 * 2),
        // fontSize: "calc(1em + ((100vw - 1440px) / (2880 - 1440)))",
        // fontSize: "20px",
        // ["@media(max-width: 1400px)"]: { fontSize: fs },
      }}
      {...props}
    >
      {/* <Header /> */}
      {children}
      {/* Footer */}
    </div>
  )
}

export const ContentWrapper = styled.section({
  maxWidth: toEm(1100),
  margin: "0 auto",
  padding: `0 ${toEm(32)}`,
})
export const ContentWrapperDiv = ContentWrapper.withComponent("div")

const bigButtonStyle: (variant: {
  variant?: "normal" | "black"
  color?: string
}) => CSSObject = ({ color, variant = "normal" }) => ({
  display: "inline-block",
  textAlign: "center",
  cursor: "pointer",
  padding: "0.75em 1.3em",
  fontSize: toEm(28),
  letterSpacing: 1,
  fontWeight: 600,
  textDecoration: "none",
  transition: "background-color .5s",
  backgroundColor: "unset",
  ":hover": {
    backgroundColor: lighten(0.4)(color ?? theme.colors.black),
  },
  boxShadow: `0px ${toEm(3)} ${toEm(6)} rgba(0,0,0,0.16)`,
  borderRadius: toEm(12),
  borderWidth: toEm(4, 28),
  borderStyle: "solid",
  ...(variant === "normal"
    ? {
        borderColor: color ?? theme.colors.red,
        color: color ?? theme.colors.red,
      }
    : variant === "black"
    ? {
        borderColor: theme.colors.black,
        backgroundColor: theme.colors.black,
        color: color ?? theme.colors.white,
      }
    : {}),
})
export const BigButton = styled.button(bigButtonStyle)
export const BigButtonAnchor = styled.a(bigButtonStyle)

const buttonStyle: (variant: {
  variant?: "normal" | "filled"
  color?: string
}) => CSSObject = ({ color, variant = "normal" }) => ({
  display: "inline-block",
  textAlign: "center",
  cursor: "pointer",
  padding: `${toEm(16, 20)} ${toEm(42, 20)}`,
  fontFamily: "inherit",
  fontSize: toEm(20),
  letterSpacing: 1,
  fontWeight: 700,
  textDecoration: "none",
  transition: "background-color .5s",
  backgroundColor: "unset",
  boxShadow: `0px ${toEm(3)} ${toEm(6)} rgba(0,0,0,0.16)`,
  borderRadius: toEm(12),
  borderWidth: toEm(4, 20),
  borderStyle: "solid",
  ":hover": {
    backgroundColor:
      variant == "filled"
        ? transparentize(0.2, color ?? theme.colors.black)
        : transparentize(0.8, color ?? theme.colors.white),
  },
  ...(variant === "normal"
    ? {
        borderColor: color ?? theme.colors.red,
        color: color ?? theme.colors.red,
      }
    : variant === "filled"
    ? {
        borderColor: color ?? theme.colors.black,
        backgroundColor: color ?? theme.colors.black,
        color: readableColor(color ?? theme.colors.black),
      }
    : {}),
})
export const Button = styled.button(buttonStyle)
export const FormButton = styled(Button)({
  ...padding(toEm(20), toEm(50)),
  fontSize: "1em",
})

export const ButtonLink = styled(Link)(buttonStyle)
export const ButtonAnchor = styled.a(buttonStyle)

export const Input = styled.input({
  color: theme.colors.black,
  fontSize: "1em",
  borderRadius: toEm(12),
  boxShadow: `0 ${toEm(3)} ${toEm(6)} rgba(0, 0, 0, 0.16)`,
  ...padding(toEm(20), toEm(24)),
  ...border(toEm(2), "solid", "#F6F6F6"),
  WebkitAppearance: "none",
  "::placeholder": {
    color: "rgba(61, 61, 61, 0.5)",
  },
  ":disabled": {
    backgroundColor: "rgba(246,246,246,1)",
  },
})

export const Alert: React.FC = props => {
  return (
    <div
      css={{
        borderRadius: toEm(12),
        ...padding(toEm(20), toEm(24)),
        ...border(toEm(2), "solid", theme.colors.red),
      }}
      role="alert"
      {...props}
    />
  )
}

const rotateKeyframes = keyframes`
    100% { transform: rotate(360deg) }
`

export const LoadingButton = styled(Button)(
  ({
    color = theme.colors.red,
    fadeColor1 = readableColor(color),
    fadeColor2 = transparentize(0.7, readableColor(color)),
  }: {
    color?: string
    fadeColor1?: string
    fadeColor2?: string
  }) => ({
    ":disabled": {
      cursor: "progress",
      backgroundColor: color,
      position: "relative",
      color: "transparent",
      "&:before": {
        content: "''",
        position: "absolute",
        width: "30px",
        height: "30px",
        left: "50%",
        top: "50%",
        marginTop: "-15px",
        marginLeft: "-15px",
        animationName: rotateKeyframes,
        animationDuration: "1s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "30px",
        borderColor: fadeColor2,
        borderTopColor: fadeColor1,
        opacity: 0.5,
      },
    },
  })
)

export { SplitImage } from "./split-image"

export interface ListItemProps
  extends Omit<
    GatsbyLinkProps<any>,
    "ref" // Not valid in a function component
  > {
  icon?: React.ReactNode
}

const listItemStyle = css({
  display: "flex",
  alignItems: "center",
  ...padding(toEm(16), toEm(24)),
  color: theme.colors.black,
  textDecoration: "none",
  backgroundColor: theme.colors.grey,
  borderRadius: toEm(10),
  [bpLg]: {
    padding: toEm(32, 30),
  },
})

const listItemHoverStyle = css({
  cursor: "pointer",
  userSelect: "none",
  WebkitTapHighlightColor: "transparent",
  ":hover": {
    backgroundColor: darken(0.05, theme.colors.grey),
  },
})

export const ListItem: React.FC<ListItemProps> = ({
  icon,
  children,
  ...props
}) => {
  return (
    <Link css={[listItemStyle, props.to ? listItemHoverStyle : {}]} {...props}>
      <div css={{ flexGrow: 1, width: 0 }}>{children}</div>
      {icon && <div css={{ paddingLeft: toEm(8) }}>{icon}</div>}
    </Link>
  )
}

export const ListItemButton: React.FC<
  {
    icon?: React.ReactNode
    leftIcon?: React.ReactNode
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ icon, leftIcon, children, ...props }) => {
  return (
    <div
      css={[listItemStyle, props.onClick ? listItemHoverStyle : {}]}
      {...props}
    >
      {leftIcon && <div css={{ paddingRight: toEm(8 * 3) }}>{leftIcon}</div>}
      <div css={{ flexGrow: 1, width: 0 }}>{children}</div>
      {icon && <div css={{ paddingLeft: toEm(8) }}>{icon}</div>}
    </div>
  )
}

export const ListItemTitle = styled.div({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: 600,
  [bpLg]: {
    fontWeight: 700,
  },
})
export const ListItemSubtitle = styled.div({
  fontSize: "0.75em",

  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginTop: shevy.baseSpacing(0.1),
  [bp]: {
    fontSize: "0.8em",
  },
  [bpLg]: { marginTop: shevy.baseSpacing(0.2) },
})

export const Stack: React.FC<{ spacing?: string }> = ({
  children,
  spacing = shevy.baseSpacing(0.5),
  ...props
}) => {
  return (
    <div
      {...props}
      css={{
        // marginTop: "-" + spacing,
        "& > *": {
          marginTop: spacing,
        },
      }}
    >
      {children}
    </div>
  )
}

export const PreloadedLoader = (
  <ThreeDots
    css={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
    height={20}
    color={theme.colors.green}
  />
)

export const Loader: React.FC = ({ ...props }) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, 250)
    return function cleanup() {
      clearTimeout(timeout)
    }
  }, [])

  return show ? (
    <div css={{ position: "relative", minHeight: "100vh" }} {...props}>
      {PreloadedLoader}
    </div>
  ) : null
}
